import ApiService from "@/common/api.service";
import { FETCH_ADMIN_FEATURES } from "./actions.type";
import { SET_ADMIN_FEATURES } from "./mutations.type";

export const state = {
  adminFeatures: {},
};

const getters = {
  adminFeatures(state) {
      return state.adminFeatures;
  },
};

export const actions = {
  [FETCH_ADMIN_FEATURES](context) {
    return ApiService.get("general/adminMenu/")
      .then(({ data }) => {
        context.commit(SET_ADMIN_FEATURES, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
};

export const mutations = {
  [SET_ADMIN_FEATURES](state, article) {
    state.adminFeatures = article;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
